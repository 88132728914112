import { FaHome, FaInfoCircle, FaUserGraduate } from "react-icons/fa";

const navbarItems = [
  {
    title: "Home",
    link: "/",
    icon:<FaHome/>
  },
  {
    title: "About",
    link: "/about",
    icon:<FaInfoCircle/>
  },
  // {
  //   title: "Services",
  //   link: "/Services",
  // },
  {
    title: "Jobs",
    link: "/ManPower",
    icon:<FaUserGraduate/>
  },
];

export default navbarItems;
