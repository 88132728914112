import { useState } from "react"
import { Description, DetailPanel, Heading, Padding, Values } from "../components/Basic/Basic"
import { Footer } from "../components/Footer/Footer"
import { CountriesSlider, Slider, UniversitySlider } from "../components/Slider/Slider"
import { Testimonials } from "../components/Testimonial/Testimonial"
import { ToggleSwitch } from "../components/ToggleSwitch/ToggleSwitch"
import { ManPower } from "../ManPower/ManPower"
export const Home = () => {
    const countries = [
        {
            image: "./images/indiaf.gif",
            country: "India",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/canadaf.gif",
            country: "Canada",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/americaf.gif",
            country: "America",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/australiaf.gif",
            country: "Australia",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/ukf.gif",
            country: "UK",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/newzealandf.gif",
            country: "New Zealand",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/Irelandf.gif",
            country: "Ireland",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/germanyf.gif",
            country: "Germany",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/euf.gif",
            country: "Europe",
            details: "Cillum qui est excepteur laborum nisi."
        }
    ]
    const [isEducation, handleEducation] = useState(true);
    const conso = (val) => {
        console.log(val);
        handleEducation(val)
    }
    return <>
        <Slider />
        <Padding />
        <Heading title="What are you looking for ?" Color={process.env.REACT_APP_Black} animation="typewriter" />
        <Padding />
        <ToggleSwitch isEducation={isEducation} handleEducation={conso} />

        {isEducation ? <>
            <Padding />
            <div className="bg1">
                <Heading title="Education" Color={process.env.REACT_APP_Black} />
                <Description description="Studying in world’s top university is not a dream anymore. By assisting you in reaching your goals, we at Sancruz International are here to help you make the best choice. Our experts are aware of your dilemma, so we  are here to help you decide on the best course, university and country for you." />
                <DetailPanel type="studyAbroad" image="./images/studyAbroad.gif" color={process.env.REACT_APP_Black} background={process.env.REACT_APP_NEW} />
            </div>
            <div className="bgTopCountries">
                <Description description="Choose Your Favorite Destination " />
                <div className="marginBottonTopCountries">
                    <Heading title="Top Countries" Color={process.env.REACT_APP_Black} />
                </div>
                <CountriesSlider countries={countries} disableClick={false} />
            </div>

            <div className="bgTestimonials">
                <div className="marginTestimonial">
                    <Heading title="Lets Hear It from Our Clients." />
                </div>
                <Testimonials />
            </div>
            <div className="bgValues">
                <Values />
            </div>
            <Footer />
        </>
            :
            <div className="manpowerMarginHome"><ManPower /></div>
        }

    </>
}