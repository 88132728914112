import { useEffect } from "react"
import { Description, DetailPanel, Heading, Values } from "../components/Basic/Basic"
import { Footer } from "../components/Footer/Footer"
import { CountriesSlider } from "../components/Slider/Slider"

export const ManPower = ({ skipScrollToTop = false }) => {
    useEffect(() => {
        if (skipScrollToTop)
            window.scrollTo(0, 0)
    }, [])
    const countries = [
        {
            image: "./images/ukf.gif",
            country: "UK",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/croatiaf.gif",
            country: "Croatia",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/polandf.gif",
            country: "Poland",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/slovakia.gif",
            country: "Slovakia",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/netherlandf.gif",
            country: "Netherland",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/denmarkf.gif",
            country: "Denmark",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/hungaryf.gif",
            country: "Hungary",
            details: "Cillum qui est excepteur laborum nisi."
        },
        {
            image: "./images/uaef.gif",
            country: "UAE",
            details: "Cillum qui est excepteur laborum nisi."
        },
    ]
    return (
        <div className="manPowerMargin">
            <div className="manService">

                <Heading title="man power" Color={process.env.REACT_APP_Black} />
                <Description description="Are you a Skilled worker looking for a job? Then Sancruz International is the right place for you. Our experts are ready to help you through out your journey." />
                <DetailPanel type="manpower" image="./images/manpower.gif" color={process.env.REACT_APP_Black} background={process.env.REACT_APP_NEW} />
            </div>
            <div className="manTopCountreis">
                <Description description="Better life Better earnings" />
                <Heading title="Top Preffered Countries" Color={process.env.REACT_APP_Black} />
                <CountriesSlider countries={countries} disableClick={true} />
            </div>
            <div className="bgValues">
                <Values />
            </div>
            <Footer />
        </div>
    )
}
