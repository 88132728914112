import React, { useState, useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Sidebar from "./components/Sidebar/Sidebar";
import { Routes, Route } from "react-router-dom";
import { Slider, University, UniversitySlider } from "./components/Slider/Slider";
import { DetailPanel, Heading, Padding, Description, Whatsapp, SocialMediaIcons, TopBar } from "./components/Basic/Basic";
import { Education } from "./Education/Education";
import { Home } from "./Home/Home";
import 'animate.css';
import { ManPower } from "./ManPower/ManPower";
function App() {
  const [isopen, setisopen] = useState(false);
  const toggle = () => {
    setisopen(!isopen);
  };

  return (
    <>
      <Navbar toggle={toggle} />
      <Sidebar isopen={isopen} toggle={toggle} />
      <SocialMediaIcons />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/services" element={<Services />} /> */}
        <Route path="/ManPower" element={<div className="manpowerMargin"><ManPower skipScrollToTop={true} /></div>} />
        <Route path="/University" element={<UniversitySlider />} />
      </Routes>
      {/* </Snowfall> */}
    </>
  );
}

export default App;
