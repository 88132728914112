import React from "react";
import "./Navbar.css";
import navbarItems from "../NavbarItems";
import { Link } from "react-router-dom";
import { FaBars, FaHome } from "react-icons/fa";
import { Pulse, Zoom } from "react-reveal";
import { TopBar } from "../Basic/Basic";

const Navbar = ({ toggle }) => {
  return (
    <>
      <TopBar />
      <nav>
        <Link to="/" className="link">
          <img className="logo" src="./images/logo.png" />
        </Link>
        <div className="menu-items">
          {navbarItems.map((item, index) => (
            <Link className="link" to={item.link} key={index}>
              <div className="menuIcon"> {item.icon}</div>  {item.title}
            </Link>
          ))}
        </div>
        <div className="icons">
          <div className="mobile-menu-icon">
            <FaBars onClick={toggle} />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
