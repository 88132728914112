import 'react-slideshow-image/dist/styles.css'
import { Zoom } from 'react-slideshow-image';
import "./Slider.css"
import Carousel from 'react-grid-carousel'
import { useLocation, useNavigate } from 'react-router';
import { Heading } from '../Basic/Basic';
import { FaUniversity } from 'react-icons/fa';
import { useEffect } from 'react';
// import 'react-multi-carousel/lib/styles.css';
const images = [
    'images/banner1.webp',
    'images/banner2.webp',
    'images/banner3.webp',
    'images/banner4.webp',
];
export const Slider = () => {
    return (
        <div className="slide-container">
            <Zoom scale={1.4} indicators={true}>
                {
                    // images.map((each, index) => <img key={index} style={{ width: "100%" }} src={each} />)
                    // <img key={index} style={{ width: "100%" }} src={each} />
                    images.map((each, index) => { return <div key={index} className="sliderImage" style={{ backgroundImage: `url(${each})` }} /> }
                    )
                }
            </Zoom>
        </div>
    )
}


export const Country = ({ image, country, details, disableClick }) => {
    let navigate = useNavigate();
    return <div className='countryBox' onClick={!disableClick ? () => { navigate("/University", { state: { country: country } }) } : null}>
        <div className="a-box">
            <div className="img-container ">
                <div className="img-inner">
                    <div className="inner-skew">
                        <img src={image} />
                    </div>
                </div>
            </div>
            <div className="text-container">
                <h3>{country}</h3>
                {/* <div>
                    {details}
                </div> */}
            </div>
        </div>
    </div>
}

export const University = ({ name }) => {
    return <>
        {/* <div className="container"> */}
        <div className="card">
            <div className="card-header">
                <FaUniversity size="3.5em" style={{ color: process.env.REACT_APP_White }} />
                {/* <img src={image} alt="rover" /> */}
            </div>
            <div className="card-body">
                <span className="tag tag-teal">{name}</span>
            </div>
        </div>

        {/* </div> */}
    </>
}
export const CountriesSlider = ({ countries, disableClick }) => {

    return <div className="containerMargin" style={{ backgroundColor: process.env.REACT_APP_NEW }}> <Carousel
        cols={4}
        rows={1}
        gap={11}
        responsiveLayout={[
            {
                breakpoint: 1200,
                cols: 3
            },
            {
                breakpoint: 990,
                cols: 2
            }
        ]}
        mobileBreakpoint={670}
    // arrowRight={<ArrowBtn type="right" />}
    // arrowLeft={<ArrowBtn type="left" />}
    >

        {countries.map((country, i) => (
            <Carousel.Item key={i}>
                <Country {...country} disableClick={disableClick} />
            </Carousel.Item>
        ))}
    </Carousel>
    </div>
}

export const UniversitySlider = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { state } = useLocation();
    var universities = [];
    const allUniversities = [
        {
            country: "America",
            universities:
                ["The University of Chicago", "Princeton University Princeton", "Yale University New Haven", "Georgia Institute of Technology Atlanta",
                    "Duke University Durham", "Johns Hopkins University Baltimore", "Columbia University New York", "University of Pennsylvania Philadelphia", "University of Michigan",
                    "University of California, Los Angeles", "The University of Texas at Austin", "Brown University Providence", "Cornell University"]
        },
        {
            country: "New Zealand",
            universities: [

                "University of Waikato", "Victoria University of Wellington", "Massey University", "Auckland University of Technology",
                "Lincoln University", "UNITEC Institute of Technology", "Auckland Institute Of Studies", "Otago Polytechnic", "Eastern Institute Of Technology", "Manukau Institute of Technology"
            ]
        },
        {
            country: "Canada",
            universities: [
                "University of Toronto", "McGill University", "University of British Columbia", "Université de Montréal", "University of Alberta",
                "McMaster University", "University of Waterloo", "Western University", "Queen's University", "University of Calgary"

            ]
        },
        {
            country: "UK",
            universities: [
                "University of Birmingham", "University of Southampton", "University of Leeds", "The University of Sheffield", "University of St Andrews", "The University of Nottingham", "Queen Mary University of London (QMUL)", "Lancaster University", "University of York", "Newcastle University", "Cardiff University"

            ]
        },
        {
            country: "Germany",
            universities: [
                " Catholic University of Eichstätt", "Jacobs University Bremen", "Witten/Herdecke University", "Frankfurt School of Finance and Management", "ESMT Berlin",
                "FOM University of Applied Sciences for Economics and Management", "Fresenius University of Applied Sciences", "Hertie School of Governance",
                "WHU - Otto Beisheim School of Management", "University of Applied Sciences Europe", "Macromedia University of Applied Sciences", "SRH University of Applied Sciences"

            ]
        },
        {
            country: "Australia",
            universities: [
                "Australian National University(ANU)", "University of Sydney", "University of Melbourne", "University of New South Wales(UNSW)", "University of Queensland(UQ)", "Monash University", "University of Western Australia(UWA)", "University of Adelaide", "University of Technology Sydney(UTS)", "University of Wollongong"
            ]
        },
        {
            country: "Ireland",
            universities: [
                "Trinity College Dublin", "National College of Ireland", "Munster Technological University", "Griffith College Dublin", "Atlantic Technological University - Donegal Letterkenny Campus", "Technological University of the Shannon: Midlands ", "Institute of Technology Carlow", " Dundalk Institute of Technology", "Atlantic Technological University Sligo", "Dublin Institute of Technology", "Dublin Business School"
            ]
        },
        {
            country: "Europe",
            universities: [
                "University of Glasgow", "Copenhagen University’s", "Moscow State University", "University of Bristle", "University of Leeds", "Humboldt University of Berlin", "Universitat de Barcelona", "The University of Sheffield", "University of Nottingham",
                "Katholieke Universiteit Leuven", "University of Vienna", "University of Helsinki", "Sorbonne Université", "University of York", "Durham University"
            ]
        },
        {
            country: "India",
            universities: [
                "Banglore university",
                "cheist(Deemed to be university)",
                "Rajeev Ghandhi university of Health science",
                "Ramaiah institute of Technology Jain(Deeened to be University",
                "Pes university ",
                "Acharya institute",
                "Reva  university"
            ]
        }



    ];
    if (state && state.country) {
        let countryBasedSearchResult = allUniversities.filter((university) => { return university.country == state.country })
        universities = countryBasedSearchResult && countryBasedSearchResult[0].universities;
    }
    return <>
        <div className="mt7"></div>
        <Heading title={"Top Universities in " + state.country} Color={process.env.REACT_APP_Black} />
        <div className="containerUniversity containerMargin">

            {/* {universities.filter((university) => { return university.country == "America" })} */}
            {universities.map((university, i) => (
                <University name={university} key={i} />
            ))}
        </div>
    </>
}