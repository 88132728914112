import { useEffect } from "react";
import { Footer } from "../Footer/Footer";
import aboutStyle from "./About.scss"
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="mt7"></div>
      <section className="light detailPanel aboutContainer">
        <div className={aboutStyle.container + "py-2"}>
          <div className="h1 text-center text-dark" id="pageHeaderTitle">About US</div>

          <article className="postcard light red">
            <a className="postcard__img_link" href="#">
              <img className="postcard__img" src="./images/about.jpg" alt="Image Title" />
            </a>
            <div className="postcard__text t-dark">
              {/* <h1 className="postcard__title blue"><a href="#">Our Vision</a></h1> */}

              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">
                <p>
                  Sancruz International, established in 2022, serves as a one-stop solution for all your immigration and educational needs. We are determined to provide immaculate educational assistance and counselling to anyone who wishes to pursue their studies at esteemed universities and colleges anywhere in the world. Along with career councelling, we will also be helping you at all stages like program selection, application process, assistance in availing education loan, visa application, post landing services etc.
                </p>
                <p>
                  Additionally, we are one of Kerala's most prominent and rapidly expanding job consultancy service provider. Our experienced mentors will help you land your dream career and will go above and beyond to allay your worries with their in-depth knowledge of a wide range of sectors.
                </p>
                <p>
                  At Sancruz International we strive to offer our clients the finest possible service, whether it be educational or job consultation services. Every member of our team is passionately committed to the success of our candidates and they live and breathe our core values every day.

                </p>
               <div className="whyAreYouWaiting"><b>So why are you still waiting? Get in touch with us for further details.</b></div>
               </div>
            </div>
          </article>
          {/* <article className="postcard light red">
            <a className="postcard__img_link" href="#">
              <img className="postcard__img" src="https://picsum.photos/1000/1000" alt="Image Title" />
            </a>
            <div className="postcard__text t-dark">
              <h1 className="postcard__title red"><a href="#">Mission</a></h1>

              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, fugiat asperiores inventore beatae accusamus odit minima enim, commodi quia, doloribus eius! Ducimus nemo accusantium maiores velit corrupti tempora reiciendis molestiae repellat vero. Eveniet ipsam adipisci illo iusto quibusdam, sunt neque nulla unde ipsum dolores nobis enim quidem excepturi, illum quos!</div>

            </div>
          </article> */}
        </div>
      </section>
      {/* <div classNameNameName="aboutContainer">
        <div classNameNameName="aboutImage">
          <img src="./images/about.jpg" />
        </div>
        <div classNameNameName="aboutContent">
          <div classNameNameName="aboutHeading">About US</div>
          <div classNameNameName="paragraph">Sit dolore ipsum sit nulla sunt irure do fugiat excepteur cillum ad sunt pariatur officia. Nisi adipisicing laboris eu culpa fugiat eu dolore ut ut duis irure. Anim aliquip eiusmod voluptate eu aute voluptate nostrud. Aliquip laboris consequat voluptate ex anim amet elit nulla dolore quis sint ullamco dolor.</div>
          <div classNameNameName="paragraph">Sit dolore ipsum sit nulla sunt irure do fugiat excepteur cillum ad sunt pariatur officia. Nisi adipisicing laboris eu culpa fugiat eu dolore ut ut duis irure. Anim aliquip eiusmod voluptate eu aute voluptate nostrud. Aliquip laboris consequat voluptate ex anim amet elit nulla dolore quis sint ullamco dolor.</div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default About;
