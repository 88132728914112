import { Zoom } from 'react-reveal';
import Testimonial from 'react-testimonial';
import "./Testimonial.css"
export const Testimonials = () => {
    const style = {

    }
    return <Testimonial  timer="600000">
        <div className="detailPanel  content mx-auto" style={style}>
         <div className="tcardBody slide-top">
                 <span className='testimonialItem slide-top'>
                    <figure className="snip1390">
                        {/* <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample3.jpg" alt="profile-sample3" className="profile" /> */}
                        <figcaption>
                            <h2>Joy Thomas</h2>
                            {/* <h4>UX Design</h4> */}
                            <blockquote>Sancruz International have been extremely helpful throughout my journey to reach Australia. I would like to thank the team for their constant support and guidance during every step of my journey</blockquote>
                        </figcaption>
                    </figure>
                

                </span>
                {/* <p>Abc</p> */}
            </div>
        </div>
        <div className="detailPanel  content mx-auto" style={style}>
            <div className="tcardBody">
                 <span className='testimonialItem slide-top'>
                    <figure className="snip1390 hover ">
                        {/* <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample5.jpg" alt="profile-sample5" className="profile" /> */}
                        <figcaption>
                            <h2>Vivek Sreenivas</h2>
                            <blockquote>Sancruz International is the most trustable consultancy service in Kerala. Their most friendly, knowledgeable and helpful mentors helped me to get admission in one of the best university in Canada. If you are looking to study abroad, the Sancruz is the right place for you!</blockquote>
                        </figcaption>
                    </figure>
                     

                </span>
                {/* <p>Xyz</p> */}
            </div>
        </div>
        <div className="detailPanel  content mx-auto" style={style}>
            <div className="tcardBody">
                 <span className='testimonialItem slide-top'>
                    <figure className="snip1390">
                        {/* <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample6.jpg" alt="profile-sample6" className="profile" /> */}
                        <figcaption>
                            <h2>Sangeeth Sivan</h2>
                             <blockquote>Sancruz International is the right place for job aspirants. The team is very supportive and dedicated. They helped me in getting my dream job based on my experience and skill set.</blockquote>
                        </figcaption>
                    </figure>
                     
                </span>
                {/* <p>Xyz</p> */}
            </div>
        </div>

    </Testimonial>
}