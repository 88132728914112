import { FaAddressCard, FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaMailchimp, FaMobile, FaPhoneAlt, FaWhatsapp } from "react-icons/fa"
import { Description, Heading, Padding } from "../Basic/Basic"
import "./Footer.css"
export const Footer = () => {
    return <>
        <div className="bgContact">
        <Description description="Why are you waiting" />
            <Heading title="Contact Us" Color={process.env.REACT_APP_White} />

            <div className="footerContainer">
                <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.1756078766375!2d77.6304310148212!3d12.89642709090521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1feb8e9e12016792!2zMTLCsDUzJzQ3LjEiTiA3N8KwMzcnNTcuNCJF!5e0!3m2!1sen!2sin!4v1663151349100!5m2!1sen!2sin" width="100%" height="450" allowFullScreen="" loading="lazy" frameBorder="0" referrerPolicy="no-referrer-when-downgrade" />

                </div>
                <div className="contact">
                    <div className="phone">
                        <div className="footerIcon"><FaPhoneAlt /></div>
                        <Padding />
                        <div><a href="tel:08088785371">08088785371</a></div>
                        {/* <Padding />
                    <div><a href="tel:+91442548745">+91442548745</a></div> */}
                    </div>
                    <div className="email">
                        <div className="footerIcon"><FaEnvelope /></div>
                        <div><a href="mailto:sancruz.int@gmail.com">sancruz.int@gmail.com</a></div>
                    </div>
                    <div className="address">
                        <div className="footerIcon"><FaAddressCard /></div>
                        {/* <Padding /> */}
                        <span> Nagaraju Building 3rd floor
                            7 th main 6th cross
                            Muniyappa layout,Garebavipalaya
                            560068, Bengaluru</span>

                    </div>
                </div>

                <div className="footerBar">
                    <div>
                        <a href="https://wa.me/08088785371">
                            <FaWhatsapp className="topBarIcon" />
                        </a>
                    </div>
                    <div>
                        <a href="mailto:sancruz.int@gmail.com">
                            <FaEnvelope className="topBarIcon" />
                        </a>
                    </div>
                    <div>
                        <a href="https://www.facebook.com/profile.php?id=100085410565133">
                            <FaFacebook className="topBarIcon" />
                        </a>
                    </div>
                    <div>
                        <a href="https://instagram.com/sancruzinternational?igshid=YmMyMTA2M2Y=">
                            <div className="social-btnTop   ">
                                <FaInstagram className="topBarIcon" />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="footerBar">
                    <div> Home <span /></div>
                    <div> About <span /></div>
                    <div> Jobs</div>
                    {/* <div> Job</div> */}


                </div>
            </div></div>
    </>
}