import "./Basic.css"
import Zoom from 'react-reveal/Zoom';
import { Fade, Slide } from "react-reveal";
import { useState, useRef } from "react";
import { FaAward, FaBook, FaChartBar, FaCommentDollar, FaEnvelope, FaFacebook, FaFunnelDollar, FaGlobeEurope, FaHandHolding, FaHandshake, FaInstagram, FaLightbulb, FaMeetup, FaMoneyBill, FaPeopleCarry, FaPhone, FaPhoneSquare, FaPiggyBank, FaPlane, FaQuoteLeft, FaQuoteRight, FaRegHandshake, FaRegLightbulb, FaServicestack, FaShieldAlt, FaSignal, FaTaxi, FaUniversity, FaUser, FaUserFriends, FaUserGraduate, FaUsers, FaWhatsapp } from 'react-icons/fa';
export const Heading = ({ title, Color, animation = "animate__animated animate__zoomIn", cStyle = {} }) => {
  const ref = useRef(null);
  return <div ref={ref} className={"heading " + animation} style={{ "color": Color, cStyle }
  }>
    {/* {console.log(inViewport)} */}
    < div className="h1" style={{ "color": Color }} > {title}</div >
    {/* <Zoom when={inViewport} appear={inViewport}> <h1>{title}</h1></Zoom> */}
    {/* <Zoom unmountOnExit={true} when={inViewport} appear={inViewport}> <h1>{title}</h1></Zoom> */}
    {/* <button onClick={() => setAppear(!inViewport)}>ba</button> */}
  </div >
}
export const Description = ({ description }) => {
  return <Fade bottom><div className="description">{description}</div ></Fade>
}
export const DetailPanel = ({ detail = "", image, color, type, background = "white" }) => {

  return <div style={{ "backgroundColor": background }} className="detailPanel">
    {type != "manpower" ? <>
      <div style={{ "color": color }} className="details">
        <div className="detailsRow">
          <div className="detailIcon"><FaUserGraduate size="3.5em" /></div>
          <div className="detailText">Career counselling </div>
        </div>

        <div className="detailsRow">
          <div className="detailIcon"><FaUniversity size="3.5em" /></div>
          <div className="detailText">Course & university selection </div>
        </div>
        <div className="detailsRow">
          <div className="detailIcon"><FaLightbulb size="3.5em" /></div>
          <div className="detailText">Admisson procedure </div>
        </div>
        <div className="detailsRow">
          <div className="detailIcon"><FaPiggyBank size="3.5em" /></div>
          <div className="detailText">Educational loan  </div>
        </div>
        <div className="detailsRow">
          <div className="detailIcon"><FaPlane size="3.5em" /></div>
          <div className="detailText">Visa guidance </div>
        </div>
        <div className="detailsRow">
          <div className="detailIcon"><FaPeopleCarry size="3.5em" /></div>
          <div className="detailText">Post landing services  </div>
        </div>
        {/* <div className="detailsRow">
          <div className="detailIcon"><FaGlobeEurope size="3.5em" /></div>
          <div className="detailText">travel desk assistance </div>
        </div> */}

      </div></> : <>
      <div style={{ "color": color }} className="details">
        <div className="detailsRow">
          <div className="detailIcon"><FaHandshake size="3.5em" /></div>
          <div className="detailText">Career Counselling</div>
        </div>
        <div className="detailsRow">
          <div className="detailIcon"><FaPlane size="3.5em" /></div>
          <div className="detailText">Visa assistance </div>
        </div>

        <div className="detailsRow">
          <div className="detailIcon"><FaGlobeEurope size="3.5em" /></div>
          <div className="detailText">Travel assistance </div>
        </div>
        <div className="detailsRow">
          <div className="detailIcon"><FaPeopleCarry size="3.5em" /></div>
          <div className="detailText">Post landing services </div>
        </div>
        <div className="detailsRow">
          <div className="detailIcon"><FaCommentDollar size="3.5em" /></div>
          <div className="detailText">TRP assistance </div>
        </div>
        <div className="detailsRow">
          <div className="detailIcon"><FaTaxi size="3.5em" /></div>
          <div className="detailText">Airport pickup</div>
        </div>

      </div>
    </>
    }
  </div >
}

export const Padding = () => {
  return <div className="paddingElements" />
}

export const Whatsapp = () => {
  return <div className="whatsapp"><a href="https://api.whatsapp.com/send?phone=8747947761">
    <img className="animate__animated animate__pulse animate__infinite	infinite" src="images/whatsapp.png" />
  </a></div>
}

export const SocialMediaIcons = () => {
  return <>
    <div className="social">
      <a href="https://wa.me/08088785371">
        <div className="social-btn  animate__animated animate__pulse animate__infinite	infinite color-whatsapp">
          <div className="icons8-whatsapp"></div>
          {/* <p className="order-1 google-font margin-instagram">whats app</p> */}
        </div>
      </a>
      <a href="mailto:sancruz.int@gmail.com">
        <div className="social-btn  animate__animated animate__pulse animate__infinite	infinite color-gmail">
          <FaEnvelope className="gmail" />
        </div>
      </a>
      <a href="https://www.facebook.com/profile.php?id=100085410565133">
        <div className="social-btn  animate__animated animate__pulse animate__infinite	infinite color-fb">
          <FaFacebook className="fb" />
        </div>
      </a>
      <a href="https://instagram.com/sancruzinternational?igshid=YmMyMTA2M2Y=">
        <div className="social-btn  animate__animated animate__pulse animate__infinite	infinite color-instagram">
          <div className="icons8-instagram"></div>
          {/* <p className="order-1 google-font margin-instagram">instagram</p> */}
        </div>
      </a>

    </div>
  </>
}

export const TopBar = () => {

  return <div className="topBarContainer">
    {/* <div className="leftTopBar"></div> */}
    <a href="https://wa.me/08088785371">
      <div className="social-btnTop animate__animated animate__pulse animate__infinite	infinite  ">
        <FaWhatsapp className="topBarIcon" />
      </div>
    </a>
    <a href="mailto:sancruz.int@gmail.com">
      <div className="ocial-btnTop animate__animated animate__pulse animate__infinite	infinite  ">
        <FaEnvelope className="topBarIcon" />
      </div>
    </a>
    <a href="https://www.facebook.com/profile.php?id=100085410565133">
      <div className="social-btnTop animate__animated animate__pulse animate__infinite	infinite ">
        <FaFacebook className="topBarIcon" />
      </div>
    </a>
    <a href="https://instagram.com/sancruzinternational?igshid=YmMyMTA2M2Y=">
      <div className="social-btnTop animate__animated animate__pulse animate__infinite	infinite  ">
        <FaInstagram className="topBarIcon" />
        {/* <p className="order-1 google-font margin-instagram">instagram</p> */}
      </div>
    </a></div>
}

export const Values = () => {
  return <div className="valueContainer">
    <div className="vHead"><div className="h1">Why Sancruz International?</div></div>
    {/* <div className="vDesc">Committed to providing a world-class experience in every aspect</div> */}
    <div className="values">
      <div className="value">
        <div className="vicon"><FaShieldAlt /></div>
        <div className="text">Certification</div>
      </div>
      <div className="value">
        <div className="vicon"><FaUsers /></div>
        <div className="text">Guidance</div>
      </div>
      <div className="value">
        <div className="vicon"><FaSignal /></div>
        <div className="text">Perfection</div>
      </div>
      <div className="value">
        <div className="vicon"><FaAward /></div>
        <div className="text">Quality</div>
      </div>


      <div className="value">
        <div className="vicon"><FaPhoneSquare /></div>
        <div className="text">Follow ups</div>
      </div>
      <div className="value">
        <div className="vicon"><FaChartBar /></div>
        <div className="text">Course Guidance</div>
      </div>

    </div>
  </div>
}